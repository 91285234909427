
// intervention data
export class InterventionData
{
	interventionTypeId: number;
	interventionTypeName: string;
	scheduling: Date;

	interventionParameters: InterventionParamater[];
	interventionConstraints: InterventionConstraints;
	purgeWorkTeams: InterventionPurgeWorkTeam[];
}
export interface InterventionParamater
{
	id: number;
	name: string;
}
export interface InterventionConstraints
{
	mainVehicleSizeId: number;
	mainVehicleSizeName: string;
	mainVehicleMaxHeight: number;
	mainVehicleMaxPipeDiameter: number;
	mainVehicleDrainsTrunk: boolean;
	mainVehicleMilanoAreaB: boolean;
	mainVehicleBergamoZtl: boolean;
	secondaryVehicleSizeId: number;
	secondaryVehicleSizeName: string;
	secondaryVehicleBox: boolean;
	secondaryVehicleClosed: boolean;
	secondaryVehicleWasteTransport: boolean;
	secondaryVehicleSeats: number;
	secondaryVehicleMilanoAreaB: boolean;
	secondaryVehicleBergamoZtl: boolean;
}
export interface InterventionPurgeWorkTeam
{
	lead: boolean;
	operators: InterventionPurgeWorkTeamOperator[];
	vehicles: InterventionPurgeWorkTeamVehicle[];
}
export class InterventionPurgeWorkTeamOperator
{
	id: number;
	lastName: string;
	firstName: string;
	teamLeader: boolean;

	// operator attachments
	attachments: InterventionPurgeWorkTeamAttachment[];

	// operator unavailabilities
	unavailabilities: InterventionValidationUnavailability[];
}
export class InterventionPurgeWorkTeamVehicle
{
	id: number;
	licensePlate: string;
	vehicleTypeId: number;
	vehicleTypeName: string;
	adr: boolean;

	// constraints
	vehicleSizeId: number;
	vehicleSizeName: string;
	height: number;
	pipeDiameter: number;
	industrialPurge: boolean;
	publicBodyPurge: boolean;
	drainsTrunk: boolean;
	crane: boolean;
	milanoAreaB: boolean;
	bergamoZtl: boolean;
	teleinspection: boolean;
	restoration: boolean;
	box: boolean;
	closed: boolean;
	wasteTransport: boolean;
	seats: number;

	// vehicle attachments
	attachments: InterventionPurgeWorkTeamAttachment[];

	// vehicle unavailabilities
	unavailabilities: InterventionValidationUnavailability[];

	// authorized operators
	authorizedOperators: InterventionPurgeWorkTeamAuthorizedOperator[];
}
export interface InterventionPurgeWorkTeamAttachment
{
	id: number;
	fileTypeId: number;
	fileTypeName: string;
	expiryDate: Date;
}
export interface InterventionValidationUnavailability
{
	id: number;
	appointmentTypeId: number;
	appointmentTypeName: string;
	schedulingStartDate: Date;
	schedulingEndDate: Date;
}
export interface InterventionPurgeWorkTeamAuthorizedOperator
{
	id: number;
	lastName: string;
	firstName: string;
}

// intervention validation
export class InterventionValidation
{
	areas: InterventionValidationArea[];

	// helpers
	valid: boolean;

	// constructor
	public constructor()
	{
		this.areas = [];
	}
}
export class InterventionValidationArea
{
	title: string;
	description: string;
	icon: string;
	areas: InterventionValidationArea[];
	sections: InterventionValidationAreaEntitySection[];

	// helpers
	valid: boolean;
	hidden: boolean;

	// constructor
	public constructor()
	{
		this.areas = [];
		this.sections = [];
		this.hidden = true;
	}
}
export class InterventionValidationAreaEntitySection
{
	title: string;
	description: string;
	items: InterventionValidationItem[];

	// helpers
	valid: boolean;

	// constructor
	public constructor()
	{
		this.items = [];
	}
}
export class InterventionValidationItem
{
	title: string;
	summary: string;
	description: string;
	valid: boolean;
	bypassValidation: boolean;
	bypassReason: string;
	bypassAuthorizingUser: string;
	bypassDisabled: boolean;
}

export interface InterventionValidationFileType
{
	id: number;
	name: string;
}