import { DatePipe, KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { PCodeServices } from '../../../../../shared/services/pcode/pcode.service';
import { InterventionsService } from '../../../../pages/purges/interventions/interventions.service';
import { FileTypes, InterventionParameter, InterventionType, VehicleType } from '../../../../shared/enums';
import { InterventionData, InterventionPurgeWorkTeamAttachment, InterventionPurgeWorkTeamOperator, InterventionValidation, InterventionValidationArea, InterventionValidationAreaEntitySection, InterventionValidationFileType, InterventionValidationItem } from './intervention-validation.models';

@Component({
	selector: 'intervention-validation',
	templateUrl: './intervention-validation.component.html',
	styleUrls: ["./intervention-validation.component.scss"]
})

export class InterventionValidationComponent
{
	// input data
	@Input() id: number;
	@Input() historic: boolean = false;
	@Input() readOnly: boolean = false;

	// variables
	public interventionData: InterventionData;
	public validationData: InterventionValidation;
	public historicizedValidationData: InterventionValidation;
	private fileTypes: InterventionValidationFileType[];
	public operators: KeyValue<number, string>[];

	public validControlCount: number;
	public invalidControlCount: number;
	public bypassControlCount: number;
	public globalBypassDisabled = false;

	public ready = false;
	public scheduling = false;
	public isProduction: boolean;

	// constructor
	constructor
		(
			private interventionsService: InterventionsService,
			private pcodeServices: PCodeServices,
			public datepipe: DatePipe,
			public bsModalRef: BsModalRef,
			private fb: FormBuilder
		)
	{
		// check: environment
		this.isProduction = environment.production;
		this.globalBypassDisabled = !environment.production;
	}

	// init
	ngOnInit()
	{
		const fileTypesObs = this.interventionsService.getInterventionValidationFileTypes();
		const operatorsObs = this.interventionsService.getInterventionValidationOperators();

		forkJoin([fileTypesObs, operatorsObs]).subscribe(results =>
		{
			// all file types
			this.fileTypes = results[0];

			// operators
			this.operators = results[1];

			// load intervention data
			this.loadInterventionData();
		});
	}

	// load intervention data
	loadInterventionData()
	{
		this.interventionsService.getInterventionHistoricizedValidation(this.id).subscribe(result =>
		{
			this.historicizedValidationData = result;

			// check: load validation data from history
			if (this.historic)
			{
				this.validationData = this.historicizedValidationData;

				// validate all constraints
				if (this.validationData)
					this.validate();

				this.ready = true;
			}

			// load current validation data
			else
			{
				this.interventionsService.getInterventionValidationInputData(this.id).subscribe(result =>
				{
					this.interventionData = result;

					// load validation data
					this.loadValidationData();
				});
			}
		});
	}

	// load validation data
	loadValidationData()
	{
		// reset operations
		this.ready = false;
		this.validationData = new InterventionValidation();

		// purge work teams areas
		this.loadValidationArea_PurgeWorkTeams();

		// work group validation area
		this.loadValidationArea_PurgeWorkGroup();

		// check: patching historic data to current validation (already validated in past)
		if (this.historicizedValidationData)
			this.validationData = Object.assign({}, this.validationData, this.historicizedValidationData);

		// validate all constraints
		this.validate();

		// ready
		this.ready = true;
	}

	// load validation area: purge work teams
	loadValidationArea_PurgeWorkTeams()
	{
		// purge work teams validation areas
		this.interventionData.purgeWorkTeams.forEach(purgeWorkTeam =>
		{
			// purgw work teams
			var purgeWorkTeamValidationArea = new InterventionValidationArea();
			purgeWorkTeamValidationArea.title = purgeWorkTeam.lead ? 'Squadra principale' : 'Squadra di supporto';
			purgeWorkTeamValidationArea.icon = purgeWorkTeam.lead ? 'star' : 'group';

			// operators
			purgeWorkTeam.operators.forEach(operator =>
			{
				var operatorDetailsArea = new InterventionValidationArea();
				operatorDetailsArea.title = operator.lastName + ' ' + operator.firstName;
				operatorDetailsArea.description = operator.teamLeader ? 'Caposquadra' : 'Collaboratore';
				operatorDetailsArea.icon = 'account_circle';

				var purgeWorkTeamMainVehicle = purgeWorkTeam.vehicles[0];

				// ===============================
				// section: unavailabilities
				// ===============================

				var unavailabilitySection = new InterventionValidationAreaEntitySection();
				unavailabilitySection.title = 'Calendario';

				// check: unavailabilites
				var unavailabilityValidationItem = new InterventionValidationItem();
				unavailabilityValidationItem.title = `Indisponibilità dell\'operatore`;
				unavailabilityValidationItem.summary = `Verifica eventuali indisponibilità a calendario dell'operatore per la giornata dell\'intervento.`;;

				if (operator.unavailabilities.length > 0)
				{
					unavailabilityValidationItem.valid = false;

					let unavailabilities: string[] = [];

					operator.unavailabilities.forEach(appointment =>
					{
						let unavailabilityDescription = appointment.appointmentTypeName + ' ';

						if (appointment.schedulingStartDate === appointment.schedulingEndDate)
							unavailabilityDescription += ' (' + this.datepipe.transform(appointment.schedulingStartDate, 'dd/MM') + ').';
						else
						{
							let startDateFormat = this.datepipe.transform(appointment.schedulingStartDate, 'HH:mm') === '00:00' ? 'dd/MM' : 'dd/MM HH:mm';
							let endDateFormat = this.datepipe.transform(appointment.schedulingEndDate, 'HH:mm') === '00:00' ? 'dd/MM' : 'dd/MM HH:mm';

							unavailabilityDescription += ' (' + this.datepipe.transform(appointment.schedulingStartDate, startDateFormat) + ' - ' + this.datepipe.transform(appointment.schedulingEndDate, endDateFormat) + ').'
						}

						unavailabilities.push(unavailabilityDescription);
					});

					unavailabilityValidationItem.description = unavailabilities.join('<br />');
				}
				else
				{
					unavailabilityValidationItem.valid = true;
					unavailabilityValidationItem.description = `Nessuna indisponibilità registrata per il giorno ${this.datepipe.transform(this.interventionData.scheduling, 'dd/MM/yyyy')}.`;
				}

				// push item into section
				unavailabilitySection.items.push(unavailabilityValidationItem);

				// push section into area
				if (unavailabilitySection.items.length > 0)
					operatorDetailsArea.sections.push(unavailabilitySection);

				// ===============================
				// section: vehicle driving
				// ===============================

				var vehicleDrivingSection = new InterventionValidationAreaEntitySection();
				vehicleDrivingSection.title = 'Guida del veicolo';

				// check: team leader constraints
				if (operator.teamLeader)
				{
					// check: purgw work team has main vehicle
					if (purgeWorkTeamMainVehicle)
					{
						// check: authorized operator
						var unavailabilityValidationItem = new InterventionValidationItem();
						unavailabilityValidationItem.title = `Assegnazione del caposquadra all'automezzo (${purgeWorkTeamMainVehicle.licensePlate})`;
						unavailabilityValidationItem.summary = `Verifica che il caposquadra sia stato assegnato all'utilizzo dell'automezzo principale.`;
						unavailabilityValidationItem.valid = purgeWorkTeamMainVehicle.authorizedOperators.map(x => x.id).indexOf(operator.id) >= 0;
						if (unavailabilityValidationItem.valid)
							unavailabilityValidationItem.description = "Operatore assegnato ed autorizzato.";
						else
							unavailabilityValidationItem.description = "Operatore non autorizzato.";

						// push item into section
						vehicleDrivingSection.items.push(unavailabilityValidationItem);
					}

					// team leader required attachments

					// check: intervento adr
					if (this.interventionData.interventionParameters && this.interventionData.interventionParameters.find(x => x.id === InterventionParameter.InterventoAdr))
					{
						var parametersAttachmentsSection = new InterventionValidationAreaEntitySection();
						parametersAttachmentsSection.title = 'Documenti specifici per parametro di intervento: Intervento ADR';

						const requiredFileTypes =
							[
								{ ...this.fileTypes.find(x => x.id === FileTypes.PatenteAdr), ...{ checkExpiration: true, bypassDisabled: this.globalBypassDisabled } }
							];

						requiredFileTypes.forEach(fileType =>
						{
							// get attachment valiation item
							var entityValidationItem = this.getAttachmentValidationItem(fileType, operator.attachments);

							// push validation item into section
							parametersAttachmentsSection.items.push(entityValidationItem);
						});

						// push section into area
						if (parametersAttachmentsSection.items.length > 0)
							operatorDetailsArea.sections.push(parametersAttachmentsSection);
					}
				}

				// push section into area
				if (vehicleDrivingSection.items.length > 0)
					operatorDetailsArea.sections.push(vehicleDrivingSection);

				// ===============================
				// section: vehicle type
				// ===============================

				// check: purgw work team has main vehicle
				if (purgeWorkTeamMainVehicle)
				{
					var vehicleTypeSection = new InterventionValidationAreaEntitySection();
					vehicleTypeSection.title = `Documenti specifici per tipologia di automezzo: ${purgeWorkTeamMainVehicle.vehicleTypeName}`;

					if (operator.teamLeader)
					{
						// check: required file type by vehicle type
						let leadOperatorsRequiredFileTypes = [];

						switch (purgeWorkTeamMainVehicle.vehicleTypeId)
						{
							// spurghi
							case VehicleType.Spurghi:
								{
									leadOperatorsRequiredFileTypes =
										[
											{ ...this.fileTypes.find(x => x.id === FileTypes.Patente), ...{ checkExpiration: true, bypassDisabled: false } },
											{ ...this.fileTypes.find(x => x.id === FileTypes.PatenteCqc), ...{ checkExpiration: true, bypassDisabled: false } },
											{ ...this.fileTypes.find(x => x.id === FileTypes.TesseraTachigrafica), ...{ checkExpiration: true, bypassDisabled: false } }
										];
								}
								break;

							// furgoni spurghi
							case VehicleType.FurgoniSpurghi:
							case VehicleType.FurgoniTeleispezione:
								{
									leadOperatorsRequiredFileTypes =
										[
											{ ...this.fileTypes.find(x => x.id === FileTypes.Patente), ...{ checkExpiration: true, bypassDisabled: false } }
										];

									// check: crane
									if (purgeWorkTeamMainVehicle.crane === true)
										leadOperatorsRequiredFileTypes.push({ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazioneGruAutocarro), ...{ checkExpiration: true, bypassDisabled: false } });
								}
								break;
						}

						leadOperatorsRequiredFileTypes.forEach(fileType =>
						{
							// get attachment valiation item
							var entityValidationItem = this.getAttachmentValidationItem(fileType, operator.attachments);

							// push validation item into section
							vehicleTypeSection.items.push(entityValidationItem);
						})
					}

					// push section into area
					if (vehicleTypeSection.items.length > 0)
						operatorDetailsArea.sections.push(vehicleTypeSection);
				}

				// ===================================
				// section: per-parameter attachments
				// ===================================

				// check: spazi confinati
				if (this.interventionData.interventionParameters && this.interventionData.interventionParameters.find(x => x.id === InterventionParameter.SpaziConfinati))
				{
					var parametersAttachmentsSection = new InterventionValidationAreaEntitySection();
					parametersAttachmentsSection.title = 'Documenti specifici per parametro di intervento: Spazi confinati';

					const requiredFileTypes =
						[
							{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazioneMaschere), ...{ checkExpiration: true, bypassDisabled: false } },
							{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazioneImbragature), ...{ checkExpiration: true, bypassDisabled: false } },
							{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazioneSpaziConfinati), ...{ checkExpiration: true, bypassDisabled: this.globalBypassDisabled } }
						];

					requiredFileTypes.forEach(fileType =>
					{
						// get attachment valiation item
						var entityValidationItem = this.getAttachmentValidationItem(fileType, operator.attachments);

						// push validation item into section
						parametersAttachmentsSection.items.push(entityValidationItem);
					});

					// push section into area
					if (parametersAttachmentsSection.items.length > 0)
						operatorDetailsArea.sections.push(parametersAttachmentsSection);
				}

				// check: intervento adr
				if (this.interventionData.interventionParameters && this.interventionData.interventionParameters.find(x => x.id === InterventionParameter.InterventoAdr))
				{
					var parametersAttachmentsSection = new InterventionValidationAreaEntitySection();
					parametersAttachmentsSection.title = 'Documenti specifici per parametro di intervento: Intervento ADR';

					const requiredFileTypes =
						[
							{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazioneAdr), ...{ checkExpiration: true, bypassDisabled: this.globalBypassDisabled } }
						];

					requiredFileTypes.forEach(fileType =>
					{
						// get attachment valiation item
						var entityValidationItem = this.getAttachmentValidationItem(fileType, operator.attachments);

						// push validation item into section
						parametersAttachmentsSection.items.push(entityValidationItem);
					});

					// push section into area
					if (parametersAttachmentsSection.items.length > 0)
						operatorDetailsArea.sections.push(parametersAttachmentsSection);
				}

				// ===============================
				// section: mandatory attachments
				// ===============================

				var mandatoryAttachmentsSection = new InterventionValidationAreaEntitySection();
				mandatoryAttachmentsSection.title = 'Documenti obbligatori';

				// operator required file types
				const allOperatorsRequiredFileTypes =
					[
						{ ...this.fileTypes.find(x => x.id === FileTypes.ContrattoLavoro), ...{ checkExpiration: false, bypassDisabled: false } },
						{ ...this.fileTypes.find(x => x.id === FileTypes.IdoneitaSanitaria), ...{ checkExpiration: true, bypassDisabled: this.globalBypassDisabled } },
						{ ...this.fileTypes.find(x => x.id === FileTypes.ModuloConsegnaDpi), ...{ checkExpiration: true, bypassDisabled: false } },
						{ ...this.fileTypes.find(x => x.id === FileTypes.FormazioneSpecifica), ...{ checkExpiration: true, bypassDisabled: false } }
					];

				allOperatorsRequiredFileTypes.forEach(fileType =>
				{
					// get attachment valiation item
					var entityValidationItem = this.getAttachmentValidationItem(fileType, operator.attachments);

					// push validation item into section
					mandatoryAttachmentsSection.items.push(entityValidationItem);
				});

				// push section into area
				if (mandatoryAttachmentsSection.items.length > 0)
					operatorDetailsArea.sections.push(mandatoryAttachmentsSection);

				// push area into parent
				purgeWorkTeamValidationArea.areas.push(operatorDetailsArea);
			})

			// vehicles
			purgeWorkTeam.vehicles.forEach(vehicle =>
			{
				var vehicleDetailsArea = new InterventionValidationArea();
				vehicleDetailsArea.title = vehicle.licensePlate;
				vehicleDetailsArea.description = vehicle.vehicleTypeName;
				vehicleDetailsArea.icon = 'local_shipping';

				// ===============================
				// section: unavailabilities
				// ===============================

				var unavailabilitySection = new InterventionValidationAreaEntitySection();
				unavailabilitySection.title = 'Calendario';

				// check: unavailabilites
				var unavailabilityValidationItem = new InterventionValidationItem();
				unavailabilityValidationItem.title = `Indisponibilità dell\'automezzo`;
				unavailabilityValidationItem.summary = `Verifica eventuali indisponibilità a calendario dell'automezzo per la giornata dell\'intervento.`;

				if (vehicle.unavailabilities.length > 0)
				{
					unavailabilityValidationItem.valid = false;

					let unavailabilities: string[] = [];

					vehicle.unavailabilities.forEach(appointment =>
					{
						let unavailabilityDescription = appointment.appointmentTypeName + ' ';

						if (appointment.schedulingStartDate === appointment.schedulingEndDate)
							unavailabilityDescription += ' (' + this.datepipe.transform(appointment.schedulingStartDate, 'dd/MM') + ').';
						else
						{
							let startDateFormat = this.datepipe.transform(appointment.schedulingStartDate, 'HH:mm') === '00:00' ? 'dd/MM' : 'dd/MM HH:mm';
							let endDateFormat = this.datepipe.transform(appointment.schedulingEndDate, 'HH:mm') === '00:00' ? 'dd/MM' : 'dd/MM HH:mm';

							unavailabilityDescription += ' (' + this.datepipe.transform(appointment.schedulingStartDate, startDateFormat) + ' - ' + this.datepipe.transform(appointment.schedulingEndDate, endDateFormat) + ').'
						}

						unavailabilities.push(unavailabilityDescription);
					});

					unavailabilityValidationItem.description = unavailabilities.join('<br />');
				}
				else
				{
					unavailabilityValidationItem.valid = true;
					unavailabilityValidationItem.description = `Nessuna indisponibilità registrata per il giorno ${this.datepipe.transform(this.interventionData.scheduling, 'dd/MM/yyyy')}.`;
				}

				// push item into section
				unavailabilitySection.items.push(unavailabilityValidationItem);

				// push section into arearifica che l'automezzo non abbia indisponibilità a cal
				if (unavailabilitySection.items.length > 0)
					vehicleDetailsArea.sections.push(unavailabilitySection);

				// ===========================================
				// section: intervention type and parameters
				// ===========================================

				var interventionParametersSection = new InterventionValidationAreaEntitySection();
				interventionParametersSection.title = 'Tipologia e parametri di intervento';

				// main vehicle
				// ===============================

				if (vehicle.vehicleTypeId === VehicleType.Spurghi)
				{
					// check: intervention type
					switch (this.interventionData.interventionTypeId)
					{
						// spurghi industriali
						case InterventionType.SpurghiIndustriali:
							{
								var entityValidationItem = new InterventionValidationItem();
								entityValidationItem.title = "Spurgo industriale";
								entityValidationItem.summary = 'Verifica che l\'automezzo sia idoneo a svolgere questo intervento.';

								if (vehicle.industrialPurge)
								{
									entityValidationItem.description = `L'autospurgo è compatibile con questa tipologia di intervento.`;
									entityValidationItem.valid = true;
								}
								else
								{
									entityValidationItem.description = `L'autospurgo non è idoneo allo svolgimento di uno spurgo industriale.`;
									entityValidationItem.valid = false;
								}

								interventionParametersSection.items.push(entityValidationItem);
							}
							break;

						// spurghi enti pubblici
						case InterventionType.SpurghiEntiPubblici:
							{
								var entityValidationItem = new InterventionValidationItem();
								entityValidationItem.title = "Spurgo enti pubblici";
								entityValidationItem.summary = 'Verifica che l\'automezzo sia idoneo a svolgere questo intervento.';

								if (vehicle.publicBodyPurge)
								{
									entityValidationItem.description = `L'autospurgo è compatibile con questa tipologia di intervento.`;
									entityValidationItem.valid = true;
								}
								else
								{
									entityValidationItem.description = `L'autospurgo non è idoneo allo svolgimento di uno spurgo per enti pubblici.`;
									entityValidationItem.valid = false;
								}

								interventionParametersSection.items.push(entityValidationItem);
							}
							break;
					}

					// check: adr
					if (this.interventionData.interventionParameters && this.interventionData.interventionParameters.find(x => x.id === InterventionParameter.InterventoAdr))
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Intervento ADR";
						entityValidationItem.summary = 'Verifica che l\'automezzo sia idoneo a svolgere questo intervento.';

						if (vehicle.adr)
						{
							entityValidationItem.description = `L'autospurgo è configurato come ADR.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `L'autospurgo non è configurato come ADR.`;
							entityValidationItem.valid = false;
						}

						interventionParametersSection.items.push(entityValidationItem);
					}
				}

				// secondary vehicle
				// ===============================

				if (vehicle.vehicleTypeId === VehicleType.FurgoniSpurghi || vehicle.vehicleTypeId === VehicleType.FurgoniTeleispezione)
				{
					// check: intervention type
					switch (this.interventionData.interventionTypeId)
					{
						// videoispezione
						case InterventionType.Videoispezione:
							{
								var entityValidationItem = new InterventionValidationItem();
								entityValidationItem.title = "Teleispezione";
								entityValidationItem.summary = 'Verifica che l\'automezzo sia idoneo a svolgere questo intervento.';

								if (vehicle.teleinspection)
								{
									entityValidationItem.description = `Il furgone è idoneo allo svolgimento di una teleispezione.`;
									entityValidationItem.valid = true;
								}
								else
								{
									entityValidationItem.description = `Il furgone non è idoneo allo svolgimento di una teleispezione.`;
									entityValidationItem.valid = false;
								}

								interventionParametersSection.items.push(entityValidationItem);
							}
							break;
					}

					// check: risanamento
					if (this.interventionData.interventionParameters && this.interventionData.interventionParameters.find(x => x.id === InterventionParameter.RisanamentoTubazioni))
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Risanamento";
						entityValidationItem.summary = 'Verifica che l\'automezzo sia idoneo a svolgere questo intervento.';

						if (vehicle.restoration)
						{
							entityValidationItem.description = `Il furgone è configurato come idoneo al risanamento.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `Il furgone non è configurato come idoneo al risanamento.`;
							entityValidationItem.valid = false;
						}

						interventionParametersSection.items.push(entityValidationItem);
					}
				}

				// push section into area
				if (interventionParametersSection.items.length > 0)
					vehicleDetailsArea.sections.push(interventionParametersSection);

				// ===============================
				// section: eligibility features
				// ===============================

				var constraintsSection = new InterventionValidationAreaEntitySection();
				constraintsSection.title = 'Caratteristiche del mezzo';

				// main vehicle
				// ===============================

				if (vehicle.vehicleTypeId === VehicleType.Spurghi)
				{
					// vehicle size
					if (this.interventionData.interventionConstraints.mainVehicleSizeId)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Taglia";
						entityValidationItem.summary = 'Verifica che la dimensione dell\'automezzo sia idonea per questo intervento.';

						if (vehicle.vehicleSizeId)
						{
							if (vehicle.vehicleSizeId <= this.interventionData.interventionConstraints.mainVehicleSizeId)
							{
								entityValidationItem.description = `La taglia dell\'autospurgo (${vehicle.vehicleSizeName}) è minore o uguale a quella richiesta dall'intervento (${this.interventionData.interventionConstraints.mainVehicleSizeName}).`;
								entityValidationItem.valid = true;
							}
							else
							{
								entityValidationItem.description = `La taglia dell\'autospurgo (${vehicle.vehicleSizeName}) deve essere minore o uguale a ${this.interventionData.interventionConstraints.mainVehicleSizeName}.`;
								entityValidationItem.valid = false;
							}
						}
						else
						{
							entityValidationItem.description = `Taglia non censita su questo autospurgo.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// drain trunk
					if (this.interventionData.interventionConstraints.mainVehicleDrainsTrunk)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Proboscide per caditoie";
						entityValidationItem.summary = 'Verifica che le dotazioni dell\'automezzo siano idonee e compatibili per questo intervento.';

						if (vehicle.drainsTrunk)
						{
							entityValidationItem.description = `L'autospurgo è dotato di proboscide per caditoie.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `L'autospurgo non è dotato di proboscide per caditoie.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// max pipe diameter
					if (this.interventionData.interventionConstraints.mainVehicleMaxPipeDiameter)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Diametro tubazioni da pulire";
						entityValidationItem.summary = 'Verifica che le dotazioni dell\'automezzo siano idonee e compatibili per questo intervento.';

						if (vehicle.pipeDiameter)
						{
							if (vehicle.pipeDiameter >= this.interventionData.interventionConstraints.mainVehicleMaxPipeDiameter)
							{
								entityValidationItem.description = `L'autospurgo è adatto a pulire tubazioni fino ad un massimo di ${vehicle.pipeDiameter}mm (fino a ${this.interventionData.interventionConstraints.mainVehicleMaxPipeDiameter}mm per questo intervento).`;
								entityValidationItem.valid = true;
							}
							else
							{
								entityValidationItem.description = `Il diametro massimo di tubazioni gestibile da questo autospurgo (${vehicle.pipeDiameter}mm) non è sufficiente per questo intervento (fino a ${this.interventionData.interventionConstraints.mainVehicleMaxPipeDiameter}mm).`;
								entityValidationItem.valid = false;
							}
						}
						else
						{
							entityValidationItem.description = `Diametro massimo delle tubazioni non censito su questo autospurgo.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// max height
					if (this.interventionData.interventionConstraints.mainVehicleMaxHeight)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Altezza massima";
						entityValidationItem.summary = 'Verifica che la dimensione dell\'automezzo sia idonea per questo intervento.';

						if (vehicle.height)
						{
							if (vehicle.height <= this.interventionData.interventionConstraints.mainVehicleMaxHeight)
							{
								entityValidationItem.description = `L'altezza dell'autospurgo (${vehicle.height}cm) è minore o uguale a quella richiesta dall'intervento (fino a ${this.interventionData.interventionConstraints.mainVehicleMaxHeight}cm).`;
								entityValidationItem.valid = true;
							}
							else
							{
								entityValidationItem.description = `L'altezza dell'autospurgo (${vehicle.height}cm) è maggiore del limite massimo per questo intervento (fino a ${this.interventionData.interventionConstraints.mainVehicleMaxHeight}cm).`;
								entityValidationItem.valid = false;
							}
						}
						else
						{
							entityValidationItem.description = `Altezza non censita su questo autospurgo.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// area B milano
					if (this.interventionData.interventionConstraints.mainVehicleMilanoAreaB)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Area B Milano";
						entityValidationItem.summary = 'Verifica che l\'automezzo abbia i permessi di transito necessari.';

						if (vehicle.milanoAreaB)
						{
							entityValidationItem.description = `L'autospurgo ha il permesso di transito nell'area B di Milano.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `L'autospurgo non ha il permesso di transito nell'area B di Milano.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// ztl bergamo
					if (this.interventionData.interventionConstraints.mainVehicleBergamoZtl)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "ZTL Bergamo";
						entityValidationItem.summary = 'Verifica che l\'automezzo abbia i permessi di transito necessari.';

						if (vehicle.bergamoZtl)
						{
							entityValidationItem.description = `L'autospurgo ha il permesso di transito nelle ZTL di Bergamo.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `L'autospurgo non ha il permesso di transito nelle ZTL di Bergamo.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}
				}

				// secondary vehicle
				// ===============================

				if (vehicle.vehicleTypeId === VehicleType.FurgoniSpurghi || vehicle.vehicleTypeId === VehicleType.FurgoniTeleispezione)
				{
					// vehicle size
					if (this.interventionData.interventionConstraints.secondaryVehicleSizeId)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Taglia";

						if (vehicle.vehicleSizeId)
						{
							if (vehicle.vehicleSizeId <= this.interventionData.interventionConstraints.secondaryVehicleSizeId)
							{
								entityValidationItem.description = `La taglia del furgone (${vehicle.vehicleSizeName}) è minore o uguale a quella richiesta dall'intervento (${this.interventionData.interventionConstraints.secondaryVehicleSizeName}).`;
								entityValidationItem.valid = true;
							}
							else
							{
								entityValidationItem.description = `La taglia del furgone (${vehicle.vehicleSizeName}) deve essere minore o uguale a ${this.interventionData.interventionConstraints.secondaryVehicleSizeName}.`;
								entityValidationItem.valid = false;
							}
						}
						else
						{
							entityValidationItem.description = `Taglia non censita su questo furgone`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// seats
					if (this.interventionData.interventionConstraints.secondaryVehicleSeats)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "N° posti";
						entityValidationItem.summary = 'Verifica che le dotazioni dell\'automezzo siano idonee e compatibili per questo intervento.';

						if (vehicle.seats)
						{
							if (vehicle.seats >= this.interventionData.interventionConstraints.secondaryVehicleSeats)
							{
								entityValidationItem.description = `Il furgone ha ${vehicle.seats} posti, sufficienti per questo intervento (${this.interventionData.interventionConstraints.secondaryVehicleSeats} posti richiesti).`;
								entityValidationItem.valid = true;
							}
							else
							{
								entityValidationItem.description = `Il furgone ha ${vehicle.seats} posti, ma l'intervento richiede un minimo di ${this.interventionData.interventionConstraints.secondaryVehicleSeats} posti.`;
								entityValidationItem.valid = false;
							}
						}
						else
						{
							entityValidationItem.description = `Numero di posti non censito su questo furgone`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// box
					if (this.interventionData.interventionConstraints.secondaryVehicleBox)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Cassone";
						entityValidationItem.summary = 'Verifica che le dotazioni dell\'automezzo siano idonee e compatibili per questo intervento.';

						if (vehicle.box)
						{
							entityValidationItem.description = `Il furgone è dotato il cassone.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `Il furgone non ha il cassone, necessario per questo intervento.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// closed
					if (this.interventionData.interventionConstraints.secondaryVehicleClosed)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Furgone chiuso";
						entityValidationItem.summary = 'Verifica che le dotazioni dell\'automezzo siano idonee e compatibili per questo intervento.';

						if (vehicle.closed)
						{
							entityValidationItem.description = `Il furgone è chiuso.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `Il furgone non è chiuso, ma l'intervento lo richiede.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// waste transport
					if (this.interventionData.interventionConstraints.secondaryVehicleWasteTransport)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Trasporto rifiuti";
						entityValidationItem.summary = 'Verifica che le dotazioni dell\'automezzo siano idonee e compatibili per questo intervento.';

						if (vehicle.wasteTransport)
						{
							entityValidationItem.description = `Il furgone è abilitato al trasporto rifiuti.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `Il furgone non è abilitato al trasporto rifiuti, ma l'intervento lo richiede.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// area B milano
					if (this.interventionData.interventionConstraints.secondaryVehicleMilanoAreaB)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "Area B Milano";
						entityValidationItem.summary = 'Verifica che l\'automezzo abbia i permessi di transito necessari.';

						if (vehicle.milanoAreaB)
						{
							entityValidationItem.description = `Il furgone ha il permesso di transito nell'area B di Milano.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `Il furgone non ha il permesso di transito nell'area B di Milano.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}

					// ztl bergamo
					if (this.interventionData.interventionConstraints.secondaryVehicleBergamoZtl)
					{
						var entityValidationItem = new InterventionValidationItem();
						entityValidationItem.title = "ZTL Bergamo";
						entityValidationItem.summary = 'Verifica che l\'automezzo abbia i permessi di transito necessari.';

						if (vehicle.bergamoZtl)
						{
							entityValidationItem.description = `Il furgone ha il permesso di transito nelle ZTL di Bergamo.`;
							entityValidationItem.valid = true;
						}
						else
						{
							entityValidationItem.description = `Il furgone non ha il permesso di transito nelle ZTL di Bergamo.`;
							entityValidationItem.valid = false;
						}

						constraintsSection.items.push(entityValidationItem);
					}
				}

				// push section into area
				if (constraintsSection.items.length > 0)
					vehicleDetailsArea.sections.push(constraintsSection);

				// ===============================
				// section: attachments
				// ===============================

				var attachmentsSection = new InterventionValidationAreaEntitySection();
				attachmentsSection.title = 'Documenti dell\'automezzo obbligatori';

				// vehicle required file types
				let allVehiclesRequiredFileTypes =
					[
						{ ...this.fileTypes.find(x => x.id === FileTypes.LibrettoCircolazione), ...{ checkExpiration: true, bypassDisabled: false } },
						{ ...this.fileTypes.find(x => x.id === FileTypes.Assicurazione), ...{ checkExpiration: true, bypassDisabled: false } },
					];

				if (vehicle.vehicleTypeId === VehicleType.Spurghi)
				{
					allVehiclesRequiredFileTypes.push({ ...this.fileTypes.find(x => x.id === FileTypes.FoglioValiditaCisterna), ...{ checkExpiration: true, bypassDisabled: false } });
					allVehiclesRequiredFileTypes.push({ ...this.fileTypes.find(x => x.id === FileTypes.TaraturaCronotachigrafo), ...{ checkExpiration: true, bypassDisabled: false } });
				}
				else if (vehicle.vehicleTypeId === VehicleType.FurgoniSpurghi || vehicle.vehicleTypeId === VehicleType.FurgoniTeleispezione)
				{
					// check: vehicle has crane
					if (vehicle.crane)
						allVehiclesRequiredFileTypes.push({ ...this.fileTypes.find(x => x.id === FileTypes.VerificaGruCenpi), ...{ checkExpiration: true, bypassDisabled: false } });
				}

				allVehiclesRequiredFileTypes.forEach(fileType =>
				{
					// get attachment valiation item
					var entityValidationItem = this.getAttachmentValidationItem(fileType, vehicle.attachments);

					// push validation item into section
					attachmentsSection.items.push(entityValidationItem);
				})

				// push section into area
				if (attachmentsSection.items.length > 0)
					vehicleDetailsArea.sections.push(attachmentsSection);

				// push entity into area
				purgeWorkTeamValidationArea.areas.push(vehicleDetailsArea);
			})

			// adding area to result
			this.validationData.areas.push(purgeWorkTeamValidationArea);
		});
	}

	// load validation area: purge work group
	loadValidationArea_PurgeWorkGroup()
	{
		var workGroupValidationArea = new InterventionValidationArea();
		workGroupValidationArea.title = 'Gruppo di lavoro';
		workGroupValidationArea.icon = 'groups';

		// ===================================
		// section: per-parameter attachments
		// ===================================

		// check: spazi confinati
		if (this.interventionData.interventionParameters && this.interventionData.interventionParameters.find(x => x.id === InterventionParameter.SpaziConfinati))
		{
			var parametersAttachmentsSection = new InterventionValidationAreaEntitySection();
			parametersAttachmentsSection.title = 'Documenti specifici per parametro di intervento: Spazi confinati';

			var entityValidationItem = new InterventionValidationItem();
			entityValidationItem.title = "Esperienza in spazi confinati";
			entityValidationItem.summary = "Verifica che almeno il 30% della forza lavoro abbia un minimo di 3 anni di esperienza in spazi confinati.";
			entityValidationItem.bypassDisabled = this.globalBypassDisabled;

			// get all teams operators
			var workGroupOperators = this.interventionData.purgeWorkTeams.flatMap(purgeWorkTeam => purgeWorkTeam.operators);

			// operators with file type
			var operatorsWithFileType: InterventionPurgeWorkTeamOperator[] = workGroupOperators.filter(x => x.attachments.find(y => y.fileTypeId === FileTypes.Esperienza3AnniSpaziConfinati));

			entityValidationItem.valid = operatorsWithFileType.length >= workGroupOperators.length * 0.3;
			entityValidationItem.description = `${workGroupOperators.length} operatori nel gruppo di lavoro, di cui ${operatorsWithFileType.length} con esperienza minima necessaria (${operatorsWithFileType.map(operator => operator.lastName + ' ' + operator.firstName).join(', ')})`;

			// push item into section
			parametersAttachmentsSection.items.push(entityValidationItem);

			// push section into area
			workGroupValidationArea.sections.push(parametersAttachmentsSection);
		}

		// ===============================
		// section: mandatory attachments
		// ===============================

		var workGroupMandatoryAttachmentsSection = new InterventionValidationAreaEntitySection();
		workGroupMandatoryAttachmentsSection.title = 'Documenti dipendenti obbligatori';

		// work group required file types
		const workGroupRequiredFileTypes =
			[
				{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazionePreposto), ...{ summary: 'Verifica della presenza di almeno un dipendente preposto nel gruppo di lavoro.', checkExpiration: false, bypassDisabled: false } },
				{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazioneAntincendio), ...{ summary: 'Verifica della presenza di almeno un dipendente con corso antincendio in corso di validità nel gruppo di lavoro.', checkExpiration: true, bypassDisabled: this.globalBypassDisabled } },
				{ ...this.fileTypes.find(x => x.id === FileTypes.CorsoFormazionePrimoSoccorso), ...{ summary: 'Verifica della presenza di almeno un dipendente con corso di primo soccorso in corso di validità nel gruppo di lavoro.', checkExpiration: true, bypassDisabled: this.globalBypassDisabled } }
			];

		workGroupRequiredFileTypes.forEach(fileType =>
		{
			var entityValidationItem = new InterventionValidationItem();
			entityValidationItem.title = fileType.name;
			entityValidationItem.summary = fileType.summary;
			entityValidationItem.bypassDisabled = fileType.bypassDisabled;

			// get all teams operators
			var workGroupOperators = this.interventionData.purgeWorkTeams.flatMap(purgeWorkTeam => purgeWorkTeam.operators);

			// check: almost one operator has this valid attachment
			var operatorsWithFileType = workGroupOperators.filter(x => x.attachments.filter(y => y.fileTypeId === fileType.id).length > 0);

			entityValidationItem.valid = workGroupOperators
				.filter(x =>
					x.attachments.filter(y =>
						y.fileTypeId === fileType.id &&
						(new Date(y.expiryDate).getTime() > new Date().getTime())
					).length > 0).length > 0;

			if (operatorsWithFileType.length > 0)
			{
				entityValidationItem.description = operatorsWithFileType
					.map(operator => operator.lastName + ' ' + operator.firstName + ' (scadenza al ' + this.datepipe.transform(operator.attachments.find(x => x.fileTypeId === fileType.id).expiryDate, 'dd/MM/yyyy') + ')')
					.join('<br />');
			}
			else
				entityValidationItem.description = 'Nessun documento in corso di validità.';

			workGroupMandatoryAttachmentsSection.items.push(entityValidationItem);
		})

		workGroupValidationArea.sections.push(workGroupMandatoryAttachmentsSection);
		this.validationData.areas.push(workGroupValidationArea);
	}

	// get attachment validation item
	getAttachmentValidationItem(fileType: { id: number, name: string, checkExpiration: boolean, bypassDisabled: boolean }, entityAttachments: InterventionPurgeWorkTeamAttachment[]): InterventionValidationItem
	{
		var entityValidationItem = new InterventionValidationItem();
		entityValidationItem.title = fileType.name;
		entityValidationItem.summary = 'Verifica che il documento sia presente ed in corso di validità.';
		entityValidationItem.bypassDisabled = fileType.bypassDisabled;

		// check: operator existing document type
		const existingOperatorAttachment = entityAttachments.find(x => x.fileTypeId === fileType.id);

		if (existingOperatorAttachment)
		{
			// check: document expiration
			entityValidationItem.valid =
				fileType.checkExpiration ?
					existingOperatorAttachment.expiryDate ?
						(new Date(existingOperatorAttachment.expiryDate).getTime() > new Date().getTime() ? true : false) :
						false :
					true

			if (existingOperatorAttachment.expiryDate)
			{
				if (entityValidationItem.valid)
					entityValidationItem.description = `Documento valido con scadenza al ${(this.datepipe.transform(existingOperatorAttachment.expiryDate, 'dd/MM/yyyy'))}.`;
				else
					entityValidationItem.description = `Documento scaduto il ${(this.datepipe.transform(existingOperatorAttachment.expiryDate, 'dd/MM/yyyy'))}.`;
			}
			else
			{
				if (fileType.checkExpiration)
					entityValidationItem.description = `Nessun documento in corso di validità.`;
				else
					entityValidationItem.description = `Documento valido (nessuna scadenza).`;
			}
		}

		// missing document
		else
		{
			entityValidationItem.description = 'Nessun documento in corso di validità.';
			entityValidationItem.valid = false;
		}

		return entityValidationItem;
	}

	// elaborate validation
	validate()
	{
		// validate areas
		this.validateAreas(this.validationData.areas);

		// total counters
		var validationItems = this.getAllValidationItems();
		this.validControlCount = validationItems.filter(x => x.valid).length;
		this.invalidControlCount = validationItems.filter(x => !x.valid).length;
		this.bypassControlCount = validationItems.filter(x => !x.valid && x.bypassValidation && x.bypassReason && x.bypassAuthorizingUser).length;

		this.validationData.valid = this.validationData.areas.filter(x => !x.valid).length === 0;
	}
	validateAreas(currentValidationAreas: InterventionValidationArea[] = null)
	{
		currentValidationAreas.forEach(validationArea =>
		{
			if (validationArea.areas.length > 0)
			{
				this.validateAreas(validationArea.areas);
			}

			validationArea.sections.forEach(validationSection =>
			{
				validationSection.valid = validationSection.items
					.filter(x => (!x.valid && (!x.bypassValidation || !x.bypassReason || !x.bypassAuthorizingUser)))
					.length > 0 ? false : true;
			});

			if (validationArea.areas.length > 0)
				validationArea.valid = validationArea.areas.filter(x => x.valid === false).length === 0;
			else
				validationArea.valid = validationArea.sections.filter(x => x.valid === false).length === 0;
		});
	}

	// Funzione per estrarre tutti gli InterventionValidationItem
	getAllValidationItems(): InterventionValidationItem[]
	{
		const allItems: InterventionValidationItem[] = [];

		// Funzione ricorsiva per attraversare le aree
		const traverseArea = (area: InterventionValidationArea): void =>
		{
			area.sections.forEach(section => { allItems.push(...section.items); });

			// recursion
			area.areas.forEach(subArea => { traverseArea(subArea); });
		};

		// Attraversa tutte le aree principali
		this.validationData.areas.forEach(area => traverseArea(area));

		return allItems;
	}

	// refresh validation
	refreshValidation()
	{
		// load intervention data
		this.loadInterventionData();
	}

	// expand validation area
	expandValidationArea(indexHierarchy: string)
	{
		let hierarchy = indexHierarchy.split('|');
		let currentArea: InterventionValidationArea = null;

		for (let i = 0; i < hierarchy.length; i++)
		{
			if (i === 0)
				currentArea = this.validationData.areas.at(parseInt(hierarchy[i]));
			else
				currentArea = currentArea.areas.at(parseInt(hierarchy[i]));
		}

		currentArea.hidden = !currentArea.hidden;
	}

	// bypass validation change
	bypassValidationChange(event: MatSlideToggle, item: InterventionValidationItem)
	{
		item.bypassValidation = !item.bypassValidation;

		// validate all constraints
		this.validate();
	}
}