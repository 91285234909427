import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InterventionData, InterventionValidation, InterventionValidationFileType } from './intervention-validation/intervention-validation.models';
import { ChangeInterventionOperatorNotesViewModel, ChangeInterventionStatusViewModel, GetNextSubscriptionInterventionResponse, GetNoSubscriptionResponse, Intervention, InterventionRegistry, InterventionWorkTeamRegistry } from './interventions.models';

@Injectable()
export class InterventionsService
{
	// constructor
	constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string)
	{

	}

	// get list
	public getList(params: any)
	{
		return this.http.post(this.baseUrl + 'api/purges/interventions/getList', params);
	}

	// get single intervention details
	public getSingleInterventionDetails(id: number): Observable<any>
	{
		return this.http.get<any>(this.baseUrl + `api/purges/interventions/getSingleInterventionDetails/${id}`);
	}

	// get single purge note details
	public getSinglePurgeNoteDetails(id: number): Observable<any>
	{
		return this.http.get<any>(this.baseUrl + `api/purges/interventions/getSinglePurgeNoteDetails/${id}`);
	}

	// get single
	public getSingle(id: number, copyId?: number): Observable<Intervention>
	{
		return this.http.get<Intervention>(this.baseUrl + 'api/purges/interventions/getSingle?Id=' + id + '&copyId=' + copyId);
	}

	// insert/update
	public insertUpdate(intervention): Observable<number>
	{
		return this.http.post<number>(this.baseUrl + 'api/purges/interventions/insertUpdate', intervention);
	}

	// delete
	public delete(id: number)
	{
		return this.http.delete(this.baseUrl + 'api/purges/interventions/delete?id=' + id);
	}

	// get subscription checklist
	public getSubscriptionChecklist(
		subscriptionId: number,
		schedulingDate: Date,
		schedulingHour: number,
		schedulingMinutes: number,
		vehiclesIds: number[]
	)
	{
		return this.http.post<any[]>(this.baseUrl + 'api/purges/interventions/getSubscriptionChecklist',
			{
				subscriptionId: subscriptionId,
				schedulingDate: schedulingDate,
				schedulingHour: schedulingHour,
				schedulingMinutes: schedulingMinutes,
				vehiclesIds: vehiclesIds
			});
	}

	// export
	public export(params: any)
	{
		return this.http.post(this.baseUrl + 'api/purges/interventions/export', params, { responseType: 'blob' });
	}

	// get no-subscription details
	public getNoSubscriptionDetails(customerId: number): Observable<GetNoSubscriptionResponse>
	{
		return this.http.get<GetNoSubscriptionResponse>(`${this.baseUrl}api/purges/interventions/getNoSubscriptionDetails/${customerId}`);
	}

	// get next intervention subscription
	public getNextInterventionSubscription(customerId: number, officeId: number, subscriptionId?: number): Observable<GetNextSubscriptionInterventionResponse>
	{
		return this.http.get<GetNextSubscriptionInterventionResponse>(`${this.baseUrl}api/purges/interventions/getNextInterventionSubscription/${customerId}/${officeId}/${subscriptionId}`);
	}

	// check: is iot
	public isIot(id: number)
	{
		return this.http.get(this.baseUrl + 'api/purges/interventions/isIotDevice?Id=' + id);
	}

	// get intervention logs
	public getInterventionLogs(id: number): Observable<InterventionRegistry>
	{
		return this.http.get<InterventionRegistry>(this.baseUrl + `api/purges/interventions/getInterventionLogs/${id}`);
	}

	// get intervention purge work teams logs
	public getInterventionPurgeWorkTeamsLogs(id: number): Observable<InterventionWorkTeamRegistry>
	{
		return this.http.get<InterventionWorkTeamRegistry>(this.baseUrl + `api/purges/interventions/getInterventionPurgeWorkTeamsLogs/${id}`);
	}

	// get change status
	public getChangeInterventionStatus(id: number): Observable<ChangeInterventionStatusViewModel>
	{
		return this.http.get<ChangeInterventionStatusViewModel>(this.baseUrl + `api/purges/interventions/getChangeInterventionStatus/${id}`);
	}

	// change status
	public changeInterventionStatus(model)
	{
		return this.http.post(this.baseUrl + 'api/purges/interventions/changeInterventionStatus', model);
	}

	// get change operator notes
	public getChangeInterventionOperatorNotes(id: number): Observable<ChangeInterventionOperatorNotesViewModel>
	{
		return this.http.get<ChangeInterventionOperatorNotesViewModel>(this.baseUrl + `api/purges/interventions/getChangeInterventionOperatorNotes/${id}`);
	}

	// change status
	public changeInterventionOperatorNotes(model)
	{
		return this.http.post(this.baseUrl + 'api/purges/interventions/changeInterventionOperatorNotes', model);
	}

	// delete attachment
	public deleteAttachment(interventionId: number, fileId: number)
	{
		return this.http.delete(this.baseUrl + `api/purges/interventions/deleteAttachment?InterventionId=${interventionId}&FileId=${fileId}`);
	}

	// quick edit
	public quickEdit(id: number)
	{
		return this.http.post(this.baseUrl + 'api/purges/interventions/quickEdit/' + id, null);
	}

	// get intervention validation input data
	public getInterventionValidationInputData(id: number): Observable<InterventionData>
	{
		return this.http.get<InterventionData>(this.baseUrl + `api/purges/interventions/getInterventionValidationData/${id}`);
	}

	// get intervention validation operators
	public getInterventionValidationOperators(): Observable<KeyValue<number, string>[]>
	{
		return this.http.get<KeyValue<number, string>[]>(this.baseUrl + `api/purges/interventions/getInterventionValidationOperators`);
	}


	// get intervention historicized validation
	public getInterventionHistoricizedValidation(id: number): Observable<InterventionValidation>
	{
		return this.http.get<InterventionValidation>(this.baseUrl + `api/purges/interventions/getInterventionHistoricizedValidation/${id}`);
	}

	// get intervention validation file types
	public getInterventionValidationFileTypes(): Observable<InterventionValidationFileType[]>
	{
		return this.http.get<InterventionValidationFileType[]>(this.baseUrl + `api/purges/interventions/getInterventionValidationFileTypes`);
	}

	// update validation
	public updateValidation(id: number, validation: string)
	{
		return this.http.post(this.baseUrl + 'api/purges/interventions/updateValidation', { id: id, validation: validation });
	}
}