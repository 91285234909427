import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { saveAs } from 'file-saver';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { forkJoin } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { SetupService } from '../../../../shared/services/app/setup.service';
import { PCodeServices } from '../../../../shared/services/pcode/pcode.service';
import { FileTypesService } from '../../../pages/configurations/filetypes/filetypes.service';
import { AttachmentsReportingService } from '../../../shared/attachments-reporting/attachmentsreporting.service';
import { AttachmentsReportingUserModelsComponent } from '../../../shared/attachments-reporting/user-models/user-models.component';
import { Actions } from '../../../shared/enums';
import { EntityType } from './reporting-matrix.models';

@Component({
	selector: 'reporting-matrix',
	templateUrl: './reporting-matrix.component.html',
	styleUrls: ['./reporting-matrix.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ReportingMatrixComponent implements OnInit
{
	// I/O
	@Input() entityTypeIdentifier: number;

	// viewchild
	@ViewChild('ScrollableContent', { static: true, read: DragScrollComponent }) dragScrollComponent: DragScrollComponent;
	@ViewChild("userModelsSelector") userModelsSelectorModal: AttachmentsReportingUserModelsComponent;

	public entitiesRows: Array<any> = new Array<any>();
	public filteredEntitiesRows: Array<any> = new Array<any>();
	public fileTypes: Array<any>;
	public downloading = false;

	public sortFileTypeIndex: number = null;
	public sortDirection = true;
	public selectedColumns: Array<number> = new Array<number>();

	// entity type specifics
	public _pageTitle: string;
	public _cardTitle: string;
	public _searchEntityLabel: string;
	public _localStorageFilterAlias: string;

	// employees filters
	public businessAreas: any;
	public selectedBusinessAreas: string[];

	// elements filters
	public articleTypes: any;
	public filteredArticleTypes: any;

	// elements filters
	public _vehicleTypes: any;

	public categories: any;

	// search form
	public _searchForm: FormGroup = this.fb.group(
		{
			// commons
			documentExpirationType: [-1],
			documentExpiration: [null]
		}
	);
	get entityLabel() { return this._searchForm.get('entityLabel'); }
	get licensePlate() { return this._searchForm.get('licensePlate'); }
	get documentExpirationType() { return this._searchForm.get('documentExpirationType'); }
	get documentExpiration() { return this._searchForm.get('documentExpiration'); }

	// constructor
	constructor
		(
			private pcodeServices: PCodeServices,
			private attachmentsReportingService: AttachmentsReportingService,	
			private fileTypesService: FileTypesService,
			public datepipe: DatePipe,
			private fb: FormBuilder,
			private setupService: SetupService
		)
	{

	}

	// on init
	ngOnInit(): void
	{
		// init scrollable component
		this.dragScrollComponent.scrollbarHidden = true;
		this.dragScrollComponent.xWheelEnabled = false

		// common variables
		let _observables: any[10] = [];

		// loading by entity type alias
		switch (this.entityTypeIdentifier)
		{
			// employees
			case EntityType.Employees:
				{
					// ui
					this._pageTitle = 'Allegati dei dipendenti';
					this._cardTitle = 'Scadenze ed export allegati dipendenti';
					this._searchEntityLabel = "Dipendente";
					this._localStorageFilterAlias = "reporting-employees-attachments-search";

					// search form custom controls
					this._searchForm.addControl('businessAreasIds', new FormControl(null));
					this._searchForm.addControl('entityLabel', new FormControl(null));

					// observables
					const _fileTypesObs = this.fileTypesService.getByActionAlias(Actions.EmployeeAttachments);
					const _employeeObs = this.attachmentsReportingService.getEmployeesList();
					const _entitiesFileTypesObs = this.attachmentsReportingService.getEmployeeFileTypes();
					const _businessAreasObs = this.setupService.getBusinessAreas();

					_observables = [_fileTypesObs, _employeeObs, _entitiesFileTypesObs, _businessAreasObs];
				}
				break;

			// vehicles
			case EntityType.Vehicles:
				{
					this._pageTitle = 'Allegati degli automezzi';
					this._cardTitle = 'Scadenze ed export allegati automezzi';
					this._searchEntityLabel = "Automezzo";
					this._localStorageFilterAlias = "reporting-vehicles-attachments-search";

					this._searchForm.addControl('entityLabel', new FormControl(null));
					this._searchForm.addControl('licensePlate', new FormControl({ value: null, disabled: false }));
					this._searchForm.addControl('vehicleTypeId', new FormControl({ value: null, disabled: false }));

					// observables
					const _fileTypesObs = this.fileTypesService.getByActionAlias(Actions.VehicleAttachments);
					const _vehiclesObs = this.attachmentsReportingService.getVehiclesList();
					const _maintenancesTypesObs = this.setupService.getVehicleTypes();
					const _entitiesFileTypesObs = this.attachmentsReportingService.getVehiclesFileTypes();

					_observables = [_fileTypesObs, _vehiclesObs, _entitiesFileTypesObs, _maintenancesTypesObs];
				}
				break;

			// technological resources
			case EntityType.TechnologicalResources:
				{
					this._pageTitle = 'Allegati delle risorse tecnologiche';
					this._cardTitle = 'Scadenze ed export allegati risorse tecnologiche';
					this._searchEntityLabel = "Numero, matricola, modello";
					this._localStorageFilterAlias = "reporting-technological-resources-attachments-search";

					// search form custom controls
					this._searchForm.addControl('articleTypeId', new FormControl([null]));
					this._searchForm.addControl('categoryId', new FormControl([null]));
					this._searchForm.addControl('entityLabel', new FormControl([null]));

					// observables
					const _fileTypesObs = this.fileTypesService.getByActionAlias(Actions.ArticleTypeElementAttachments);
					const _categoriesObs = this.attachmentsReportingService.getAllCatalogCategories();
					const _articleTypesObs = this.attachmentsReportingService.getAllArticleTypes();
					const _entitiesFileTypesObs = this.attachmentsReportingService.getElementsFileTypes();
					const _elementsObs = this.attachmentsReportingService.getElementsList();

					_observables = [_fileTypesObs, _elementsObs, _entitiesFileTypesObs, _categoriesObs, _articleTypesObs];
				}
				break;
		}

		// fork join
		forkJoin(_observables).subscribe(results =>
		{
			this.fileTypes = results[0] as any[];
			const entities = results[1] as any[];
			const entitiesFileTypes = results[2] as any[];

			// specific observables results
			switch (this.entityTypeIdentifier)
			{
				// employees
				case EntityType.Employees:
					{
						// business areas
						this.businessAreas = results[3];

						// preselecting all business areas
						this.selectedBusinessAreas = (results[3] as Array<any>).map(x => { return x.id });
					}
					break;

				// vehicles
				case EntityType.Vehicles:
					{
						// article type 
						this._vehicleTypes = results[3];						
					}
					break;

				// technological resources
				case EntityType.TechnologicalResources:
					{
						// article type 
						this.articleTypes = results[4];
						this.filteredArticleTypes = results[4];
						// categories
						this.categories = results[3];
					}
					break;
			}

			// entities elaboration
			entities.forEach((entity) =>
			{
				// single entity file types
				const _singleEntityFileTypes = entitiesFileTypes.filter(x => x.entityId === entity.id);

				// for multiple file types per entity, sorting documents and taking the last
				const _fileTypes = new Array<any>();
				this.fileTypes.forEach((fileType, j) =>
				{
					const _newRowFileTypes = _singleEntityFileTypes
						.filter(x => x.fileTypeId === fileType.id)
						.sort((a, b) => a.entityFilesExpiryDate < b.entityFilesExpiryDate ? 1 : -1);

					_fileTypes.push(_newRowFileTypes);
				});

				// creating entity row
				const _newRow =
				{
					fileTypes: null,
					entity: null
				};

				switch (this.entityTypeIdentifier)
				{
					// employees
					case EntityType.Employees:
						{
							_newRow.entity =
							{
								label: entity.lastName + ' ' + entity.firstName,
								businessAreaIds: entity.businessAreaIds
							};
						}
						break;

					// vehicles
					case EntityType.Vehicles:
						{						
							_newRow.entity =
							{
								label: entity.licensePlate,
								vehicleTypeId: entity.vehicleTypeId,
								licensePlate: entity.licensePlate
							};
						}
						break;

					// technological resources
					case EntityType.TechnologicalResources:
						{
							_newRow.entity =
							{
								label: entity.articleTypeName + '/' + entity.number + '/' + entity.registerNumber,
								number: entity.number,
								registerNumber: entity.registerNumber,
								categoryId: entity.categoryId,
								articleTypeId: entity.articleTypeId,
								articleTypeName: entity.articleTypeName
							};
						}
						break;
				}

				// common properties
				_newRow.entity.id = entity.id;
				_newRow.fileTypes = _fileTypes

				this.entitiesRows.push(_newRow);
				this.filteredEntitiesRows.push(_newRow);
			});

			// loading filter from local storage
			this.loadFilter();
		});
	}

	// load filter from local storage
	loadFilter()
	{
		const _currentFilter = JSON.parse(localStorage.getItem(this._localStorageFilterAlias));

		if (_currentFilter)
		{
			this._searchForm.patchValue(_currentFilter);
			this.search();
		}
	}

	// search
	search()
	{
		// save current search to local storage
		localStorage.setItem(this._localStorageFilterAlias, JSON.stringify(this._searchForm.value));

		// reset entities

		this.filteredEntitiesRows = Object.assign([], this.entitiesRows);

		// filter by: label
		if (this.entityLabel.value)
		{
			this.filteredEntitiesRows = this.filteredEntitiesRows
				.filter(x => x.entity.firstName !== null && x.entity.label.toString().toLowerCase().indexOf(this.entityLabel.value.toString().toLowerCase()) >= 0);
		}

		// filter by: label
		if (this.entityTypeIdentifier == EntityType.Vehicles && this.licensePlate.value)
		{
			this.filteredEntitiesRows = this.filteredEntitiesRows
				.filter(x => x.entity.licensePlate !== null && x.entity.licensePlate.toString().toLowerCase().indexOf(this.licensePlate.value.toString().toLowerCase()) >= 0);
		}

		// filter by: document expiration
		if (this.documentExpiration.value)
		{
			const _filterDate = this.addDays(new Date(), parseInt(this.documentExpiration.value));
			const _resultArray = [];

			this.filteredEntitiesRows.forEach((entity, i) =>
			{
				entity.fileTypes.forEach((fileTypesArray, j) =>
				{
					if (fileTypesArray.length > 0)
					{
						const _fileType = fileTypesArray[0];
						let _checkFileType = false;

						if (
							(this.documentExpirationType.value > 0 && _fileType.fileTypeId == this.documentExpirationType.value) ||
							this.documentExpirationType.value < 0
						)
						{
							_checkFileType = true;
						}

						if (_checkFileType && _fileType.entityFilesExpiryDate != null)
						{
							if (
								new Date(_fileType.entityFilesExpiryDate) <= _filterDate &&
								_resultArray.indexOf(entity) < 0
							)
							{
								_resultArray.push(entity);
							}
						}
					}
				});
			});

			this.filteredEntitiesRows = Object.assign([], _resultArray);
		}

		// filtering by: entity type alias
		switch (this.entityTypeIdentifier)
		{
			// employees
			case EntityType.Employees:
				{
					// business area
					if (this.selectedBusinessAreas.length > 0)
					{
						const _validationIds = new Array<number>();

						this.filteredEntitiesRows.forEach((filteredEntity) =>
						{
							this.selectedBusinessAreas.forEach((businessArea) =>
							{
								if (filteredEntity.entity.businessAreaIds.indexOf(businessArea) >= 0)
									_validationIds.push(filteredEntity.entity.id)
							});
						});

						// filtering valid business areas
						this.filteredEntitiesRows = this.filteredEntitiesRows.filter(x => _validationIds.indexOf(x.entity.id) >= 0);
					}
				}
				break;

			// vehicles
			case EntityType.Vehicles:
				{					
					// vehicle maintenance type 
					if (this._searchForm.get('vehicleTypeId').value > 0)
					{					
						const _validationIds = new Array<number>();					
						this.filteredEntitiesRows.forEach((filteredEntity) =>
						{
							if (filteredEntity.entity.vehicleTypeId === this._searchForm.get('vehicleTypeId').value) {
								_validationIds.push(filteredEntity.entity.id)										
							}
												
						});					
						// filtering valid business areas
						this.filteredEntitiesRows = this.filteredEntitiesRows.filter(x => _validationIds.indexOf(x.entity.id) >= 0);
					}
					// vehicle license plate
					if (this._searchForm.get('licensePlate').value > 0) {
						const _validationIds = new Array<number>();
						this.filteredEntitiesRows.forEach((filteredEntity) => {
							if (filteredEntity.entity.licensePlate === this._searchForm.get('licensePlate').value) {
								_validationIds.push(filteredEntity.entity.id)
							}

						});
						// filtering valid business areas
						this.filteredEntitiesRows = this.filteredEntitiesRows.filter(x => _validationIds.indexOf(x.entity.id) >= 0);
					}
				}
				break;

			// technological resources
			case EntityType.TechnologicalResources:
				{
					// category 
					if (this._searchForm.get('categoryId').value > 0) {
						const _validationIds = new Array<number>();

						this.filteredEntitiesRows.forEach((filteredEntity) => {
							if (filteredEntity.entity.categoryId === this._searchForm.get('categoryId').value)
								_validationIds.push(filteredEntity.entity.id)
						});

						// filtering valid business areas
						this.filteredEntitiesRows = this.filteredEntitiesRows.filter(x => _validationIds.indexOf(x.entity.id) >= 0);
					}

					// article type 
					if (this._searchForm.get('articleTypeId').value > 0) {
						const _validationIds = new Array<number>();

						this.filteredEntitiesRows.forEach((filteredEntity) => {
							if (filteredEntity.entity.articleTypeId === this._searchForm.get('articleTypeId').value)
								_validationIds.push(filteredEntity.entity.id)							
						});

						// filtering valid business areas
						this.filteredEntitiesRows = this.filteredEntitiesRows.filter(x => _validationIds.indexOf(x.entity.id) >= 0);
					}
				}
				break;
		}
	}

	// select column
	selectColumn(event: MatCheckboxChange, fileTypeId: number)
	{
		this.filteredEntitiesRows.forEach((filteredEntity, i) =>
		{
			filteredEntity.fileTypes.forEach((fileTypesArray, j) =>
			{
				if (fileTypesArray.length > 0)
				{
					fileTypesArray.filter(x => x.fileTypeId == fileTypeId).forEach((fileType, k) =>
					{
						fileType.checked = event.checked;

						// updating selected columns
						if (event.checked)
						{
							if (this.selectedColumns.indexOf(fileType.fileTypeId) < 0)
								this.selectedColumns.push(fileType.fileTypeId);
						}
						else
						{
							const _index = this.selectedColumns.indexOf(fileType.fileTypeId, 0);
							if (_index > -1)
								this.selectedColumns.splice(_index, 1);
						}
					});
				}
			});
		});
	}

	// select row
	selectRow(event: MatCheckboxChange, entityId: number)
	{
		this.filteredEntitiesRows.filter(x => x.entity.id === entityId).forEach((entity) =>
		{
			entity.fileTypes.forEach((fileTypesArray) =>
			{
				if (fileTypesArray.length > 0)
				{
					fileTypesArray.forEach((fileType) =>
					{
						fileType.checked = event.checked;
					});
				}
			});
		});
	}

	// sort column
	sort(fileTypeIndex: number)
	{
		this.sortFileTypeIndex = fileTypeIndex;

		this.filteredEntitiesRows

			// empty expiry date
			.sort((a, b) =>
				(a.fileTypes[fileTypeIndex] != null && a.fileTypes[fileTypeIndex][0] != null && a.fileTypes[fileTypeIndex][0].entityFilesExpiryDate == null) ||
					(b.fileTypes[fileTypeIndex] != null && b.fileTypes[fileTypeIndex][0] != null && b.fileTypes[fileTypeIndex][0].entityFilesExpiryDate) ? -1 : 1)

			// empty file type
			.sort((a, b) => a.fileTypes[fileTypeIndex] == null || b.fileTypes[fileTypeIndex] == null ? 1 : -1)

			// empty file type at index
			.sort((a, b) => a.fileTypes[fileTypeIndex][0] == null || b.fileTypes[fileTypeIndex][0] ? 1 : -1)

			// NOT empty expiry date
			.sort(
				(a, b) =>
					(
						// check: not empty expiry date
						((a.fileTypes[fileTypeIndex] != null && a.fileTypes[fileTypeIndex][0] != null && a.fileTypes[fileTypeIndex][0].entityFilesExpiryDate != null) &&
						(b.fileTypes[fileTypeIndex] != null && b.fileTypes[fileTypeIndex][0] != null && b.fileTypes[fileTypeIndex][0].entityFilesExpiryDate != null) && // sort
                        (a.fileTypes[fileTypeIndex][0].entityFilesExpiryDate) >= (b.fileTypes[fileTypeIndex][0].entityFilesExpiryDate))
					)
						? (this.sortDirection ? 1 : -1) : (this.sortDirection ? -1 : 1)
			);

		this.sortDirection = !this.sortDirection;
	}

	// clear current selection
	clearSelection()
	{
		this.selectedColumns = new Array<number>();

		this.filteredEntitiesRows.forEach((filteredEntity, i) =>
		{
			filteredEntity.fileTypes.forEach((fileTypesArray, j) =>
			{
				if (fileTypesArray.length > 0)
				{
					fileTypesArray.forEach((fileType, k) =>
					{
						fileType.checked = false;
					});
				}
			});
		});
	}

	// add days
	addDays(date: Date, days: number): Date
	{
		date.setDate(date.getDate() + days);
		return date;
	}

	// reset filter
	resetFilter()
	{
		localStorage.removeItem(this._localStorageFilterAlias);

		// reset filter
		this.entityLabel.setValue(null);

		this.documentExpirationType.setValue(-1);
		this.documentExpiration.setValue(null);

		switch (this.entityTypeIdentifier)
		{
			// employees
			case EntityType.Employees:
				{
					this.selectedBusinessAreas = this.businessAreas.map(x => { return x.id });
				}
				break;

			// vehicles
			case EntityType.Vehicles:
				{
					this._searchForm.reset();
				}
				break;

			// technological resources
			case EntityType.TechnologicalResources:
				{
					this._searchForm.reset();
					this.filteredArticleTypes = null;
				}
				break;
		}

		this.search();
	}

	/* -----------------------------
	 * user models
	-----------------------------*/

	// show user models modal
	showUserModelsModal()
	{
		this.userModelsSelectorModal.showSelectionModal();
	}

	// user model selected
	userModelSelected(fileTypesIds: number[])
	{
		// reset current selection
		this.clearSelection();

		this.filteredEntitiesRows.forEach((filteredEntity, i) =>
		{
			filteredEntity.fileTypes.forEach((fileTypesArray, j) =>
			{
				if (fileTypesArray.length > 0)
				{
					fileTypesArray.filter(x => fileTypesIds.indexOf(x.fileTypeId) >= 0).forEach((fileType, k) =>
					{
						fileType.checked = true;

						this.selectedColumns.push(fileType.fileTypeId);
					});
				}
			});
		});

		this.userModelsSelectorModal.hideSelectionModal();
	}

	/* -----------------------------
	 * download 
	-----------------------------*/

	// download row PDF/ZIP
	downloadRow(entityId: number, ExportType: number, FileExtension: string)
	{
		// current entity row
		const _currentRow = this.entitiesRows
			.find(x => x.entity.id == entityId);

		const _selectedFilesIds = _currentRow.fileTypes
			.filter(x => x[0] != null && x[0].checked == true)
			.map(x => { return x[0].fileId; });

		// filename
		let _fileName = '';
		if (_selectedFilesIds.length == 1)
		{
			const _currentfileType = _currentRow.fileTypes
				.filter(x => x[0] != null && x[0].checked == true)
				.map(x => { return x[0]; })[0];

			_fileName = _currentfileType.fileTypeName.replace(' ', '').replace('/', '_') +
				(_currentfileType.fileTypeMultiple ? '_e_aggiornamenti' : '') +
				'-' +
				_currentRow.entity.label.replace(' ', '') +
				'.' + FileExtension;
		}
		else
			_fileName =
				_currentRow.entity.label.replace(' ', '') +
				'.' + FileExtension;

		// download
		this.download(_fileName, _selectedFilesIds, ExportType);
	}

	// download column PDF/ZIP
	downloadColumn(fileTypeId, ExportType: number, FileExtension: string)
	{
		const _selectedFilesIds = new Array<number>();

		this.filteredEntitiesRows.forEach(entityRow =>
		{
			entityRow.fileTypes.forEach(fileTypes =>
			{
				if (fileTypes.length > 0)
				{
					const _currentFile = fileTypes.filter(x => x.fileTypeId == fileTypeId && x.checked)[0];

					if (_currentFile)
						_selectedFilesIds.push(_currentFile.fileId);
				}
			});
		});

		// filename
		const _fileName = this.fileTypes.find(x => x.id == fileTypeId).name + '.' + FileExtension;

		// download
		this.download(_fileName, _selectedFilesIds, ExportType);
	}

	// download single
	downloadSingle(entityId: number, fileTypeName: string, fileId: number)
	{
		// current entity row
		const _entityRow = this.entitiesRows
			.find(x => x.entity.id == entityId);

		const _entitySelectedFilesIds = _entityRow.fileTypes
			.filter(x => x[0] != null && x[0].fileId == fileId)
			.map(x => { return { id: x[0].fileId, extension: x[0].fileExtension }; });

		const _currentfileType = _entityRow.fileTypes
			.filter(x => x[0] != null && x[0].fileId == fileId)
			.map(x => { return x[0]; })[0];

		// filename
		const _fileExtension = _entitySelectedFilesIds.map(x => x.extension);
		const _fileName =
			fileTypeName.replace(' ', '').replace('/', '_') +
			(_currentfileType.fileTypeMultiple ? '_e_aggiornamenti' : '') +
			'-' +
			_entityRow.entity.label.replace(' ', '') +
			'.' +
			_fileExtension;

		// download
		this.download(_fileName, _entitySelectedFilesIds.map(x => x.id), 1);
	}

	// download matrix
	downloadMatrix()
	{
		let _selectedFilesIds = new Array<number>();

		this.filteredEntitiesRows.forEach(entityRow =>
		{
			const _rowSelectedFilesIds = entityRow.fileTypes
				.filter(x => x[0] != null && x[0].checked == true)
				.map(x => { return x[0].fileId; });

			if (_rowSelectedFilesIds.length > 0)
				_selectedFilesIds = _selectedFilesIds.concat(_rowSelectedFilesIds);
		});

		// download
		this.download('Export matrice.zip', _selectedFilesIds, 3);
	}

	// generating and download file
	download(fileName: string, selectedFilesIds: Array<number>, exportType: number)
	{
		this.downloading = true;

		if (selectedFilesIds.length > 0)
		{
			this.attachmentsReportingService.downloadMultiple(this.entityTypeIdentifier, selectedFilesIds, exportType,)
				.pipe(timeout(1000 * 60 * 10))
				.subscribe({
                next: blob =>
                {
                    saveAs(blob, fileName);
                    this.downloading = false;
                    this.pcodeServices.notify('success', 'Download completato con successo');
                },

                error: () =>
                {
                    this.downloading = false;
                    this.pcodeServices.notify('error', 'Richiesta scaduta. Provare con una selezione di file minore');
                }
            });
		}
		else
		{
			this.downloading = false;
			this.pcodeServices.notify('error', 'Selezionare almeno un documento', 'Attenzione!');
		}
	}

	categoryIdChange(event: MatSelectChange)
	{
		this.filteredArticleTypes = this.articleTypes;

		// category 
		if (this._searchForm.get('categoryId').value > 0) {
			const _validationIds = new Array<number>();

			this.articleTypes.forEach((filteredEntity) => {
				if (filteredEntity.categoryId === this._searchForm.get('categoryId').value)
					_validationIds.push(filteredEntity.id)
			});

			// filtering valid business areas
			this.filteredArticleTypes = this.filteredArticleTypes.filter(x => _validationIds.indexOf(x.id) >= 0);
		}

		this._searchForm.get('articleTypeId').enable()
	}

	articleTypeIdChange(event: MatSelectChange) {
		this._searchForm.get('entityLabel').enable()
	}
};